import Swiper from 'swiper'
import { EffectFade, Pagination } from 'swiper/modules'

export default function heroSlider() {
  const slide = new Swiper('.swiper.hero.hero-slider', {
    modules: [EffectFade, Pagination],
    slidesPerView: 1,
    loop: true,

    direction: 'horizontal',
    effect: 'fade',

    pagination: {
      clickable: true,
      el: '.swiper-pagination',
    },
  })

  return slide
}
