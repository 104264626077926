import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let fadeElements = document.querySelectorAll(".fade-effect");

export default function fadeEffect() {
  return fadeElements.forEach((fade) => {
    gsap.from(fade, {
      opacity: 0,
      delay: 0.2,

      scrollTrigger: {
        trigger: fade,
        markers: false,
        scrub: false,
        start: "top bottom-=10%",
        end: "bottom+=10% top+=5%",
        // toggleActions: "play reverse play reverse",
      },
    });
  });
}
