import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

// Images parallax
export default function parallaxEffect() {
  return gsap.utils.toArray('.parallax-container').forEach((container) => {
    const images = container.querySelectorAll('.parallax-image')

    images.forEach((img) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          scrub: 1,
          pin: false,
          markers: false,
        },
      })

      tl.fromTo(
        img,
        {
          yPercent: 20,
          ease: 'none',
        },
        {
          yPercent: -20,
          ease: 'none',
        },
      )
    })
  })
}
