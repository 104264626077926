import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

// Images parallax
export default function fancyImageParallax() {
  return gsap.utils.toArray('.fancy-image__container').forEach((container) => {
    const fancyImage = container.querySelector('.fancy-image__img')
    const fancyImageImg = container.querySelector('.fancy-image__img img')
    const fancyBefore = container.querySelector('.fancy-image__before')
    const fancyAfter = container.querySelector('.fancy-image__after')

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: fancyImage,
        scrub: 2,
        pin: false,
        markers: false,
        end: `${fancyImage.offsetHeight}px`,
      },
    })

    tl.fromTo(
      fancyImageImg,
      {
        yPercent: 30,
        ease: 'none',
      },
      {
        yPercent: 0,
        ease: 'none',
      },
    )

    tl.fromTo(
      fancyImage,
      {
        yPercent: 0,
        ease: 'none',
      },
      {
        yPercent: -10,
        ease: 'none',
      },
    )

    tl.fromTo(
      fancyBefore,
      {
        yPercent: -10,
        ease: 'none',
      },
      {
        yPercent: 100,
        ease: 'none',
      },
      '<',
    )

    tl.fromTo(
      fancyAfter,
      {
        yPercent: 0,
        ease: 'none',
      },
      {
        yPercent: 100,
        ease: 'none',
      },
      '<',
    )
  })
}
