import AOS from 'aos'
import { Collapse } from 'bootstrap'

import fadeEffect from './fadeEffect.js'
import fadeEffectTransitionX from './fadeEffectTransitionX.js'
import fadeEffectTransitionY from './fadeEffectTransitionY.js'
import fancyImageParallax from './fancyImageParallax.js'
import heroSlider from './heroSlider.js'
import homeCardSlide from './homeCardSlide.js'
import listScrollbarSlider from './listScrollbarSlider'
import parallaxEffect from './parallaxEffect.js'
import solutionSlider from './solutionSlider.js'

AOS.init()

window.addEventListener('DOMContentLoaded', (event) => {
  heroSlider()
  homeCardSlide()
  solutionSlider()
  listScrollbarSlider()
  fadeEffect()
  parallaxEffect()
  fancyImageParallax()
  fadeEffectTransitionY()
  fadeEffectTransitionX()

  new Collapse()
})

window.addEventListener('resize', (event) => {})

window.addEventListener('scroll', (event) => {})
