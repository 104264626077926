import Swiper from "swiper";
import { Scrollbar } from "swiper/modules";

export default function listScrollbarSlider() {
  const swiper = new Swiper(".scrollbar-slider-list", {
    modules: [Scrollbar],

    slidesPerView: 1.1,
    spaceBetween: 20,

    scrollbar: {
      el: ".swiper-scrollbar",
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
      },

      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

  return swiper;
}
