import Swiper from "swiper";
import { EffectFade, Pagination } from "swiper/modules";

export default function solutionSlider() {
  const slide = new Swiper(".slider-solution .swiper", {
    modules: [EffectFade, Pagination],
    slidesPerView: 1,

    direction: "horizontal",
    loop: true,
    effect: "fade",

    // If we need pagination
    pagination: {
      clickable: true,
      el: ".swiper-pagination",
    },
  });

  return slide;
}
