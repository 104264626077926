import Swiper from "swiper";

export default function homeCardSlide() {
  const slide = new Swiper(".slider-advantages", {
    slidesPerView: 1.05,

    // Optional parameters
    direction: "horizontal",
    centeredSlides: true,
    loop: false,
    spaceBetween: 15,

    breakpoints: {
      992: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

  return slide;
}
